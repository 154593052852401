<template>
  <v-col>
    <v-row id="topAreaID" class="mt-0">
      <v-col :cols="smAndDownBreakpoint == true ? '12' : ''">
        <v-row :class="smAndDownBreakpoint == true ? '' : 'flex-nowrap'">
          <v-col
            class="pr-0 textDecorationBold font16 pb-0"
            align="left"
            :cols="smAndDownBreakpoint == true ? '12' : ''"
            :class="mobileBreakpoint == true ? '' : 'flex-grow-0 text-no-wrap '"
          >
            <span>
              {{
                $t("youHave") + " (" + unreadCoop + ") " + $t("newRequests")
              }}.
            </span>
          </v-col>
          <v-col class="flex-grow-0 pb-0">
            <v-select
              hide-details
              v-model="company"
              :items="companyList"
              item-value="id"
              item-text="name"
              clearable
              dense
              class="blueBorderedSelect font14 textDecorationBold primaryColor"
              style="width: 210px"
              solo
              flat
              @change="fetchCooperations"
            >
              <template v-slot:label>
                <span class="font14 textDecorationBold primaryColor">
                  {{ $t("allNetworks") }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span
                  class="font14 textDecorationBold primaryColor text-truncate"
                >
                  {{ item.name }}
                </span>
              </template>
              <template slot="item" slot-scope="data">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col>
                        <span
                          class="font14 textDecorationBold primaryColor text-truncate"
                        >
                          {{ data.item.name }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
          <v-col class="pr-2 flex-grow-0 pb-0">
            <v-select
              :items="getItemsList"
              dense
              v-model="options.items"
              color="primary"
              item-color="primary"
              class="blueBorderedSelect font14 textDecorationBold"
              style="max-width: 75px"
              @change="optionsRefresh"
              hide-details
              solo
              flat
            >
              <template v-slot:selection="{ item }">
                <span
                  class="font14 textDecorationBold primaryColor text-truncate"
                >
                  {{ item }}
                </span> </template
              ><template slot="item" slot-scope="data">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col>
                        <span
                          class="font14 textDecorationBold primaryColor text-truncate"
                        >
                          {{ data.item }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
          <v-col class="pb-0">
            <v-select
              hide-details
              v-model="request"
              :items="cooperationFilters"
              item-value="id"
              item-text="description"
              class="blueBorderedSelect font14 textDecorationBold primaryColor"
              style="width: 210px"
              dense
              clearable
              solo
              flat
              @change="fetchCooperations"
            >
              <template v-slot:label>
                <span class="font14 textDecorationBold primaryColor">
                  {{ $t("allRequests") }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span
                  class="font14 textDecorationBold primaryColor text-truncate"
                >
                  {{ item.description }}
                </span> </template
              ><template slot="item" slot-scope="data">
                <v-list>
                  <v-list-item-title>
                    <v-row>
                      <v-col>
                        <span
                          class="font14 textDecorationBold primaryColor text-truncate"
                        >
                          {{ data.item.description }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="align-end topLeftCol">
        <v-row class="topFirstRow align-center justify-end">
          <v-col class="flex-grow-0 pr-1">
            <v-btn
              class="btn-custom whiteBackground"
              @click="() => openDialog('filter', null)"
              key="fBtn2"
              outlined
              color="primary"
            >
              <Icon icon="bx:filter-alt" height="25" outline color="#263573" />
            </v-btn>
          </v-col>
          <v-col class="flex-grow-0 pr-2">
            <v-btn-toggle
              dense
              v-model="viewChoice"
              mandatory
              group
              tile
              color="primary"
            >
              <v-btn :id="viewChoice == 0 ? 'active-button' : ''" class="ml-1">
                <Icon
                  icon="clarity:grid-view-solid"
                  height="25"
                  :color="viewChoice == 0 ? 'white' : '#263573'"
                />
              </v-btn>
              <v-btn
                v-if="mobileBreakpoint != true"
                :id="viewChoice == 1 ? 'active-button' : ''"
                class="ml-1"
              >
                <Icon
                  icon="ant-design:unordered-list-outlined"
                  height="30"
                  :color="viewChoice == 1 ? 'white' : '#263573'"
                />
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col class="px-0 pb-2 flex-grow-0">
            <v-pagination
              class="main-pgt"
              v-model="options.page"
              :total-visible="6"
              @input="fetchCooperations"
              :length="totalPages"
            >
            </v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-0 pb-2">
      <v-col align="left" class="d-flex flex-nowrap overflow-auto">
        <FilterTag
          v-for="(tag, index) in tagFilterArray"
          :key="index"
          :display="tag.display"
          :deleteHandler="deleteFilter"
          :attrType="tag.attrType"
          :attrHead="tag.attrHead"
        />
        <a
          v-if="tagFilterArray && tagFilterArray.length > 0"
          class="text-decoration-underline text-no-wrap"
          color="#43425D"
          @click="clearAllFilter"
        >
          {{ $t("clear") }}</a
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-row v-if="loading || dataFoundStatus != 'true'" justify="center">
          <LoaderGif v-if="loading" />
          <NoData
            v-else-if="dataFoundStatus == 'false'"
            :showBtn="true"
            :btnTxt="$t('editFilters')"
            :btnClicked="() => openDialog('filter', null)"
          />
          <ServerProblem v-else-if="dataFoundStatus == 'error'" />
        </v-row>
        <v-row
          v-else-if="viewChoice == 0"
          class="noScrollBar requestCardRow"
          :style="
            !mobileBreakpoint
              ? `min-height: 450px !important; max-height:${getReportHeight}'px; overflow: auto;`
              : ''
          "
          :class="mobileBreakpoint ? 'justify-center' : 'justify-start'"
        >
          <RequestCard
            v-for="d in data"
            :key="d._id"
            :index="d._id"
            :createdDate="d.RegistrationDate"
            :code="d.EstateCode"
            :subCode="'ID ' + d._id"
            :fromDetails="{
              fullName: d.ApplicantName,
              img: d.ApplicantPhoto,
              company: d.ApplicantCompany,
              isImg: d.isImg,
              showIcon: false,
              userId: d.ApplicantID,
            }"
            :toDetails="{
              fullName: d.AgentName,
              img: d.AgentPhoto,
              company: d.AgentCompany,
              isImg: d.isImgAgent,
              showIcon: false,
              userId: d.AgentID,
            }"
            :showApproveActions="getShowApproveBtn(d)"
            :showCustomBtn="getShowCustomBtn(d)"
            :customBtnObj="getCustomBtnObj(d)"
            :customBtnHandler="
              d.IsApproved && !d.IsDeleted && d.HasAccepted && !d.IsRemoved
                ? () => deleteCooperation(d)
                : d.IsDeleted
                ? () => openDialog('slider', d)
                : void 0
            "
            :status="getStatus(d)"
            :onClickHandler="() => openDialog('slider', d)"
            :approveHandler="approveActionHandler"
            class="request-card"
            :topText="$t('from2')"
            :botText="$t('coopTo')"
          />
        </v-row>
        <v-row v-else class="mt-n6">
          <CustomTable
            :headers="reportHeaders"
            :itemsPerPage="options.items"
            :totalPageVisible="6"
            :fixedHeader="true"
            :tableHeight="getTableHeight"
            :templates="templates"
            :data="data"
            :loading="loading"
            class="row-height-85"
            :supportApproved="true"
            :handleApproved="(d) => approveActionHandler(d, true)"
            :approveItem="
              role == ['Admin', 'Secretary', 'OfficeAdmin'].includes(role)
                ? 'IsApproved'
                : 'HasAccepted'
            "
            :handleRejected="(d) => approveActionHandler(d, false)"
            :supportDeleteAttr="true"
            deleteAttr="showDeleteBtn"
            :handleDelete="deleteCooperation"
            :supportView="true"
            :handleView="openSlider"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-footer
      inset
      padless
      absolute
      style="background-color: #ebebeb"
      v-if="data.length > 0"
      :class="mobileBreakpoint == true ? 'pb-10' : ''"
    >
      <v-row class="mb-0" style="max-width: 100% !important">
        <v-col align="left" class="main-pgt pt-4 footerCl">
          {{ $t("footerP1") }} {{ options.page ? options.page : 0 }}
          {{ $t("footerP2") }} {{ options.items ? options.items : 0 }}
          {{ $t("footerP3") }} {{ totalResult ? totalResult : 0 }}
          {{ $t("footerP4") }}
        </v-col>
      </v-row>
    </v-footer>
    <Filters
      @close="this.closeDialog"
      :onSearch="fetchCooperations"
      :onCloseHandler="this.closeDialog"
      v-if="this.dialog.type == 'filter'"
    />
    <RealEstateSlider
      v-if="this.dialog.type == 'estate' && this.dialog.open"
      :data="
        dialogArray && dialogArray.length > 0
          ? dialogArray[dialogArray.length - 1].data
          : dialog.data
      "
      :onEdit="onEdit"
      :openMediaSlider="openDialog"
      :fetchContactById="fetchContactById"
      :uploadSuccessHandler="uploadDocSuccess"
      :updateEstate="updateEstate"
      :refreshEstate="fetchSpecificEstate"
      :onHandleDelete="onDeleteEstate"
      @close="closeDialogEstate"
    />
    <RequestSlider
      :data="this.dialog.data"
      v-if="this.dialog.type == 'slider' && this.dialog.open"
      :onCloseHandler="closeDialog"
      :openEstateDetailsSlider="openEstateSlider"
      :approveHandler="approveActionHandler"
    />

    <v-dialog
      v-model="deleteDialog.open"
      v-if="deleteDialog.type == 'delete'"
      width="500"
    >
      <DeletePopup
        :onRemove="onDeleteCoopHandler"
        :onCancel="closeDeleteDialog"
        cancelText="Ακύρωση"
        deleteText="Ναι, είμαι σίγουρος"
        :content="deleteDialog.content"
        :item="deleteDialog.data"
        :loading="deleteLoading"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.open"
      v-if="dialog.open && dialog.type == 'reject'"
      max-width="546"
      max-height="476"
      min-width="546"
      min-height="476"
      :elevation="0"
      flat
      style="background-color: transparent"
    >
      <PopUpWithAction
        image="general/humanPop.png"
        :height="476"
        :width="546"
        title="Το αίτημα συνεργασίας απορρίφθηκε!"
        subTitle="Αποστολή λόγου απόρριψης στους συνεργάτες;"
        :titleFont="20"
        titleColor="#263573"
        :subTitleFont="20"
        subTitleColor="#263573"
        textAreaTitle="Λόγος απόρριψης αιτήματος συνεργασίας..."
        buttonTitle="Αποστολή"
        buttonColor="#E52628"
        :actionHandler="actionRejectHandler"
        :closeHandler="closeDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.open"
      v-if="dialog.open && dialog.type == 'success'"
      max-width="546"
      max-height="398"
      min-width="546"
      min-height="398"
    >
      <ApCooperationPopup :content="dialog.data" :type="dialog.type" />
    </v-dialog>
  </v-col>
</template>

<script>
/* 
  Name:Cooperations
  Description: Cooperations view is the main page for the cooperations. In this page there is the cooperations among associates with report and cards view. Also there is filters and slider view for each cooperation.
  
  Components Used:
    Icon
    Filters
    FilterTag
    CustomTable
    LoaderGif
    RequestCard
    RequestSlider
    DeletePopup
    PopUpWithAction
    ServerProblem
    NoData
    ApCooperationPopup

  Endpoints Functions Called:
  -ReqCooperationRepo
    update
    get

  -CompaniesRepo
    get
*/

import { mapState, mapGetters } from "vuex";
import { Icon } from "@iconify/vue2";
import Filters from "@/components/Requests/Filters.vue";
import FilterTag from "@/components/Filters/FilterTag.vue";
import RequestHelper from "@/helpers/Requests/request.js";
import CustomTable from "@/components/CustomTable/CustomTable";
import RequestCard from "@/components/Requests/RequestCard";
import RequestModal from "@/static/requests/request.js";
import Repository from "@/services/Repository";
import CoreHelper from "@/helpers/Core/core.js";
import RequestSlider from "@/components/Requests/RequestSlider.vue";
import DeletePopup from "@/components/Popups/DeletePopup.vue";
import PopUpWithAction from "@/components/Popups/PopUpWithAction.vue";
import ApCooperationPopup from "@/components/Popups/ApCooperationPopup.vue";
import i18n from "@/i18n/i18n";
import RealEstateSlider from "@/components/RealEstate/RealEstateSlider/RealEstateSlider.vue";
import ServerProblem from "@/components/Popups/ServerProblem.vue";
import NoData from "@/components/Popups/NoData.vue";
import LoaderGif from "@/components/Popups/LoaderGif.vue";
const RealEstateRepo = Repository.get("realEstate");
const CompaniesRepo = Repository.get("companies");
const ReqCooperationRepo = Repository.get("reqCooperation");
export default {
  name: "Cooperations",
  components: {
    Icon,
    Filters,
    FilterTag,
    CustomTable,
    LoaderGif,
    RequestCard,
    RequestSlider,
    DeletePopup,
    PopUpWithAction,
    ServerProblem,
    RealEstateSlider,
    NoData,
    ApCooperationPopup,
  },
  data() {
    return {
      viewChoice: 1,
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      deleteDialog: {
        open: false,
        type: null,
        data: null,
        content: null,
      },
      deleteLoading: false,
      totalPages: 0,
      totalResult: 0,
      options: {
        page: 1,
        items: 20,
      },
      filterObject: [],
      loading: false,
      data: [],
      request: null,
      dataFoundStatus: null,
      company: null,
      companyList: [],
      cooperationFilters: RequestModal.cooperationFilters,
      headers: RequestModal.headers,
      templates: RequestModal.templates,
      unreadCoop: 0,
      height: null,
      windowHeight: null,
      windowWidth: null,
      prevDialogData: null,
    };
  },
  async created() {
    this.fetchCooperations();
    await this.initList();
    await this.fetchCooperationById(826);

    this.$nextTick(() => {
      this.getHeight();
    });
  },
  mounted() {
    window.addEventListener("resize", this.getHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  watch: {
    mobileBreakpoint() {
      if (this.mobileBreakpoint == true) this.viewChoice = 0;
    },
  },
  computed: {
    ...mapGetters(["getItemsList"]),
    ...mapState({
      filters: (state) => state.request,
      role: (state) => state.auth.userDetails.Role,
      userDetails: (state) => state.auth.userDetails,
    }),
    getTableHeight() {
      return this.windowHeight - this.height - 265;
    },
    getReportHeight() {
      return this.windowHeight - this.height - 240;
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    smAndDownBreakpoint() {
      return this.$vuetify.breakpoint.width < 1016;
    },
    tagFilterArray() {
      return this.filterObject[0];
    },
    reportHeaders() {
      return this.headers.map((m) => {
        m.text = i18n.t(m.text);
        return m;
      });
    },
  },
  methods: {
    getHeight() {
      var element = document.getElementById("topAreaID");
      var positionInfo = element.getBoundingClientRect();
      this.height = positionInfo.height;
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    async initList() {
      this.companyList = await this.fetchCompany();
    },
    async fetchCompany() {
      try {
        const resp = await CompaniesRepo.get({
          action: "get_companies",
        });
        return [
          ...[
            { name: "Όλα τα δίκτυα", id: -2 },
            { name: "Το δίκτυο μου", id: -1 },
          ],
          ...resp.data,
        ];
      } catch (err) {
        console.log(err, "Error");
      }
    },
    openDialog(type, data) {
      this.dialog = {
        open: true,
        type,
        data,
      };
    },

    async openEstateSlider(id) {
      this.prevDialogData = this.dialog.data;
      let data = await this.getSpecificEstate(id);

      this.openDialog("estate", data);
    },
    async getSpecificEstate(id) {
      let resp = await RealEstateRepo.getId(id);

      return resp.data.estates[0] ? resp.data.estates[0] : null;
    },
    closeDialog(update) {
      this.dialog = {
        open: false,
        type: null,
        data: null,
      };
      if (update) {
        //fetch cooperations
      }
    },
    closeDialogEstate() {
      this.openDialog("slider", this.prevDialogData);
    },
    openDeleteDialog(type, data, content) {
      this.deleteDialog = {
        open: true,
        type,
        data,
        content,
      };
    },
    closeDeleteDialog() {
      this.deleteDialog = {
        open: false,
        type: null,
        data: null,
      };
    },
    async optionsRefresh() {
      this.options.page = 1;
      await this.fetchCooperations();
    },
    clearAllFilter() {
      this.$store.dispatch("onClearFilters");
      this.fetchCooperations();
    },
    async deleteFilter(attrHead, attrType) {
      if (attrType == "string") {
        for (let i = 0; i < attrHead.length; i++) {
          await this.$store.dispatch(attrHead[i].action, null);
        }
      }
      await this.fetchCooperations();
    },
    openSlider(data) {
      this.openDialog("slider", data);
    },
    async fetchCooperations() {
      try {
        let object;
        this.loading = true;
        let params;
        this.closeDialog();
        this.filterObject = await RequestHelper.setFilterObject(this.filters);
        object = this.filterObject[1];

        // filter with inbox outbox
        if (this.request == 2) {
          // inbox
          object.AgentID = this.userDetails._id;
        } else if (this.request == 3) {
          object.ApplicantID = this.userDetails._id;
        }

        //filter with company
        if (this.company != null && this.company != "" && this.company > -1) {
          object.CreationCompany = this.company;
        } else if (
          this.company != null &&
          this.company != "" &&
          this.company == -1
        ) {
          console.log("My network selected");
        }

        params = {
          page: this.options.page - 1,
          items: this.options.items,
        };
        var resp = await ReqCooperationRepo.get(object, params);
        if (resp.data.unread_requests != undefined) {
          this.unreadCoop = resp.data.unread_requests;
        }

        if (
          resp.data != undefined &&
          resp.data.cooperation_requests != undefined
        ) {
          if (resp.data.cooperation_requests.length > 0) {
            this.dataFoundStatus = "true";
            this.data = resp.data.cooperation_requests.map((c) => {
              c.RegistrationDateFormatted = CoreHelper.EpochToDateTime(
                c.RegistrationDate,
                " | "
              );
              c.expireTimeLeft =
                c.isDeleted != true || c.isRemoved != true
                  ? c.IsApproved != true || c.HasAccepted != true
                    ? CoreHelper.ExpireAt(c.RegistrationDate, 1440)
                    : ""
                  : "";
              if (c.ApplicantPhoto == undefined || c.ApplicantPhoto == "") {
                let name = c.ApplicantName.split(/\s/).reduce(
                  (response, word) => (response += word.slice(0, 1)),
                  ""
                );
                c.ApplicantPhoto = name;
                c.isImg = false;
              } else {
                c.isImg = true;
              }
              if (c.AgentPhoto == undefined || c.AgentPhoto == "") {
                let name = c.AgentName.split(/\s/).reduce(
                  (response, word) => (response += word.slice(0, 1)),
                  ""
                );
                c.AgentPhoto = name;
                c.isImgAgent = false;
              } else {
                c.isImgAgent = true;
              }

              if (
                c.IsApproved &&
                !c.IsDeleted &&
                c.HasAccepted &&
                !c.IsRemoved
              ) {
                c.showDeleteBtn = true;
              } else if (
                ["Admin", "Secretary", "OfficeAdmin"].includes(this.role)
              ) {
                if (c.IsDeleted || c.IsApproved) {
                  c.showDeleteBtn = true;
                }
              }

              if (c.IsRemoved != undefined && c.IsRemoved) {
                c.isIconStatus = false;
                c.iconText = "Διεγραμμένο";
                c.iconTextClass = "primaryColor textDecorationBold";
              } else if (c.IsApproved && c.HasAccepted) {
                c.isIconStatus = false;
                c.iconText = "Ολοκληρωμένο";
                c.iconTextClass =
                  "primaryColor textDecorationBold align-self-center";
              } else if (c.IsDeleted) {
                c.isIconStatus = true;
                c.iconObj = {
                  icon: "ant-design:close",
                  color: "#3A3A40",
                  height: "25",
                };
              } else if (
                ["Admin", "Secretary", "OfficeAdmin"].includes(this.role)
              ) {
                if (!c.IsApproved) {
                  c.isIconStatus = true;
                  c.iconObj = {
                    icon: "mdi:timer-sand-empty",
                    color: "#E52628",
                    height: "25",
                  };
                } else {
                  c.isIconStatus = true;
                  c.iconObj = {
                    icon: "ant-design:check",
                    color: "#77BD86",
                    height: "25",
                  };
                }
              } else if (this.role == "Associate") {
                if (!c.HasAccepted) {
                  c.isIconStatus = true;
                  c.iconObj = {
                    icon: "mdi:timer-sand-empty",
                    color: "#E52628",
                    height: "25",
                  };
                } else {
                  c.isIconStatus = true;
                  c.iconObj = {
                    icon: "ant-design:check",
                    color: "#77BD86",
                    height: "25",
                  };
                }
              }
              if (["Admin", "Secretary", "OfficeAdmin"].includes(this.role)) {
                c.isAvatar = true;
              } else {
                c.isAvatar = false;
                if (c.ApplicantID == this.userDetails["_id"]) {
                  c["ApplicantNameIsAvatar"] = true;
                  c.iconFieldSubContent = i18n.t("outgoingRequest");
                  c.iconFieldΙconObj = {
                    icon: "fa6-solid:arrow-trend-up",
                    height: "28",
                  };
                  c.iconFieldColor = "#707070";
                } else if (c.AgentID == this.userDetails["_id"]) {
                  c["AgentNameIsAvatar"] = true;
                  c.iconFieldSubContent = i18n.t("incomingRequest");
                  c.iconFieldΙconObj = {
                    icon: "fa6-solid:arrow-trend-down",
                    height: "28",
                  };
                  c.iconFieldColor = "#77BD86";
                  c.iconFieldΙconColClass = "iconFlip";
                }
              }

              return c;
            });
            this.totalResult = resp.data.results;
            this.totalPages = Math.ceil(this.totalResult / this.options.items);
          } else {
            this.dataFoundStatus = "false";
            this.data = [];
            this.totalResult = 0;
            this.totalPages = 0;
          }
        }

        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "error";
        this.loading = false;
        throw e;
      }
    },
    async fetchCooperationById(id) {
      try {
        let object = { _id: id };
        this.loading = true;
        let params;
        params = {
          page: 0,
          items: 1,
        };
        var resp = await ReqCooperationRepo.get(object, params);

        if (
          resp.data != undefined &&
          resp.data.cooperation_requests != undefined
        ) {
          if (resp.data.cooperation_requests.length > 0) {
            var item = resp.data.cooperation_requests[0];
            if (item.ApplicantPhoto == undefined || item.ApplicantPhoto == "") {
              let name = item.ApplicantName.split(/\s/).reduce(
                (response, word) => (response += word.slice(0, 1)),
                ""
              );
              item.ApplicantPhoto = name;
              item.isImg = false;
            } else {
              item.isImg = true;
            }
            if (item.AgentPhoto == undefined || item.AgentPhoto == "") {
              let name = item.AgentName.split(/\s/).reduce(
                (response, word) => (response += word.slice(0, 1)),
                ""
              );
              item.AgentPhoto = name;
              item.isImgAgent = false;
            } else {
              item.isImgAgent = true;
            }

            if (
              item.IsApproved &&
              !item.IsDeleted &&
              item.HasAccepted &&
              !item.IsRemoved
            ) {
              item.showDeleteBtn = true;
            } else if (
              ["Admin", "Secretary", "OfficeAdmin"].includes(this.role)
            ) {
              if (item.IsDeleted || item.IsApproved) {
                item.showDeleteBtn = true;
              }
            }

            if (item.IsRemoved != undefined && item.IsRemoved) {
              item.isIconStatus = false;
              item.iconText = "Διεγραμμένο";
              item.iconTextClass = "primaryColor textDecorationBold";
            } else if (item.IsApproved && item.HasAccepted) {
              item.isIconStatus = false;
              item.iconText = "Ολοκληρωμένο";
              item.iconTextClass = "primaryColor textDecorationBold";
            } else if (item.IsDeleted) {
              item.isIconStatus = true;
              item.iconObj = {
                icon: "ant-design:close",
                color: "#3A3A40",
                height: "25",
              };
            } else if (
              ["Admin", "Secretary", "OfficeAdmin"].includes(this.role)
            ) {
              if (!item.IsApproved) {
                item.isIconStatus = true;
                item.iconObj = {
                  icon: "mdi:timer-sand-empty",
                  color: "#E52628",
                  height: "25",
                };
              } else {
                item.isIconStatus = true;
                item.iconObj = {
                  icon: "ant-design:check",
                  color: "#77BD86",
                  height: "25",
                };
              }
            } else if (this.role == "Associate") {
              if (!item.HasAccepted) {
                item.isIconStatus = true;
                item.iconObj = {
                  icon: "mdi:timer-sand-empty",
                  color: "#E52628",
                  height: "25",
                };
              } else {
                item.isIconStatus = true;
                item.iconObj = {
                  icon: "ant-design:check",
                  color: "#77BD86",
                  height: "25",
                };
              }
            }

            this.data = this.data.map((c) => {
              if (c._id == item._id) {
                return item;
              }
              return c;
            });
          }
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },
    deleteCooperation(data) {
      let content = `Πρόκειται να διαγράψετε το αίτημα ζήτησης ${data._id}`;

      this.openDeleteDialog("delete", data, content);
    },
    async onDeleteCoopHandler() {
      await ReqCooperationRepo.update(this.deleteDialog.data._id, {
        IsRemoved: true,
      });
      this.fetchCooperationById(this.deleteDialog.data._id);
      this.closeDeleteDialog();
    },
    async approveActionHandler(index, action) {
      if (action && ["Admin", "Secretary", "OfficeAdmin"].includes(this.role)) {
        await ReqCooperationRepo.update(index._id, {
          IsApproved: true,
          isRead: true,
        });
        this.fetchCooperationById(index._id);
        this.openDialog("success", i18n.t("cooperationApproved"));
      } else if (action && this.role == "Associate") {
        await ReqCooperationRepo.update(index._id, {
          HasAccepted: true,
          isRead: true,
        });
        this.openDialog("success", i18n.t("cooperationApproved"));
      } else if (!action) {
        this.openDialog("reject", index._id);
      }
    },
    async actionRejectHandler(data) {
      await ReqCooperationRepo.update(this.dialog.data, {
        IsDeleted: true,
        reasonOfDenial: data,
        isRead: true,
      });
      this.closeDialog();
    },
    getShowApproveBtn(d) {
      if (["Admin", "Secretary", "OfficeAdmin"].includes(this.role)) {
        if (!d.IsApproved && !d.IsDeleted) {
          return true;
        }
        return false;
      } else if (this.role == "Associate") {
        if (!d.IsDeleted && (d.HasAccepted == undefined || !d.HasAccepted)) {
          return true;
        }
        return false;
      }
    },
    getStatus(d) {
      if (d.IsApproved && d.HasAccepted) {
        return {
          type: "string",
          string: "Ολοκληρωμένο",
          class: "textDecorationBold font14 align-self-center",
          style: "color:#9B9B9B",
        };
      } else if (d.IsRemoved) {
        return {
          type: "string",
          string: "Διεγραμμένο",
          class: "textDecorationBold font14 align-self-center",
          style: "color:#9B9B9B",
        };
      } else if (!d.IsApproved && !d.IsDeleted) {
        return {
          type: "icon",
          icon: "mdi:timer-sand-empty",
          color: "#E52628",
          height: "25",
        };
      } else if (d.IsApproved) {
        return {
          type: "icon",
          icon: "ant-design:check",
          color: "#77BD86",
          height: "25",
        };
      } else if (d.IsDeleted) {
        return {
          type: "icon",
          icon: "ant-design:close",
          color: "#3A3A40",
          height: "25",
        };
      }
      //previous bussiness
      // if (d.IsApproved && d.HasAccepted) {
      //   return { type: 'string', string: 'Ολοκληρωμένο', class: 'textDecorationBold font14 align-self-center', style: 'color:#9B9B9B' };
      // } else if (d.IsRemoved) {
      //   return { type: 'string', string: 'Διεγραμμένο', class: 'textDecorationBold font14 align-self-center', style: 'color:#9B9B9B' };
      // } else if (['Admin', 'Secretary', 'OfficeAdmin'].includes(this.role)) {
      //   if (!d.IsApproved && !d.IsDeleted) {
      //     return { type: 'icon', icon: 'mdi:timer-sand-empty', color: '#E52628', height: '25' };
      //   } else if (d.IsApproved) {
      //     return { type: 'icon', icon: 'ant-design:check', color: '#77BD86', height: '25' };
      //   } else if (d.IsDeleted) {
      //     return { type: 'icon', icon: 'ant-design:close', color: '#77BD86', height: '25' };
      //   }
      // } else if (this.role == 'Associate') {
      //   if (d.HasAccepted != undefined && !d.HasAccepted && !d.IsDeleted) {
      //     return { type: 'icon', icon: 'mdi:timer-sand-empty', color: '#E52628', height: '25' };
      //   } else if (d.HasAccepted != undefined && d.HasAccepted) {
      //     return { type: 'icon', icon: 'ant-design:check', color: '#77BD86', height: '25' };
      //   } else if (d.IsDeleted) {
      //     return { type: 'icon', icon: 'ant-design:close', color: '#77BD86', height: '25' };
      //   }
      // }
    },
    getShowCustomBtn(d) {
      if (d.IsApproved && !d.IsDeleted && d.HasAccepted && !d.IsRemoved) {
        return true;
      } else if (["Admin", "Secretary", "OfficeAdmin"].includes(this.role)) {
        if (d.IsDeleted || d.IsApproved) {
          return true;
        }
      } else if (this.role == "Associate") {
        if (d.IsDeleted || d.HasAccepted) {
          return true;
        }
      } else if (d.IsDeleted) {
        return true;
      }
      return false;
    },
    getCustomBtnObj(d) {
      if (d.IsApproved && !d.IsDeleted && d.HasAccepted && !d.IsRemoved) {
        return {
          description: "Διαγραφή",
          style: "text-transform: unset !important",
          class: "ma-1",
          color: "primary",
          showIcon: true,
          iconObj: {
            icon: "ant-design:delete",
            height: 15,
            color: "white",
          },
        };
      } else if (["Admin", "Secretary", "OfficeAdmin"].includes(this.role)) {
        if (d.IsDeleted || d.IsApproved) {
          return {
            description: "Διαγραφή",
            style: "text-transform: unset !important",
            class: "ma-1",
            color: "primary",
            showIcon: true,
            iconObj: {
              icon: "ant-design:delete",
              height: 15,
              color: "white",
            },
          };
        }
      } else if (this.role == "Associate") {
        if (d.IsDeleted || d.HasAccepted) {
          return {
            description: "Διαγραφή",
            style: "text-transform: unset !important",
            class: "ma-1",
            color: "primary",
            showIcon: true,
            iconObj: {
              icon: "ant-design:delete",
              height: 15,
              color: "white",
            },
          };
        }
      } else if (d.IsDeleted) {
        return {
          description: "Γιατί δεν εγκρίθηκε?",
          style: "text-transform: unset !important",
          class: "ma-1",
          color: "primary",
          showIcon: true,
          iconObj: {
            icon: "ant-design:delete",
            height: 15,
            color: "white",
          },
        };
      }
      return {};
    },
  },
};
</script>

<style scoped>
#active-button {
  background-color: #263573 !important;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle > .v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.noScrollBar::-webkit-scrollbar {
  display: none;
}

.requestCardRow {
  overflow: auto;
}

.request-card {
  min-height: 280px;
  min-width: 370px;
  max-height: 373px;
  /* max-width: 370px; */
}

@media only screen and (max-width: 1431px) {
  .request-card {
    min-width: 300px;
    max-width: 300px;
  }
}

@media only screen and (max-width: 1230px) {
  .request-card {
    min-width: 48%;
    max-width: 48%;
  }
}

@media only screen and (max-width: 780px) {
  .request-card {
    min-width: 335px;
    max-width: 100%;
  }
}
</style>
