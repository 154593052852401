<template>
  <v-card :min-width="width" :min-height="height" :width="width" :height="height" :elevation="0">
    <v-row>
      <v-col>
        <v-img class="rounded" max-height="123" max-width="192" height="123" width="192" :src="tempImage" />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pt-2 pr-0 textDecorationBold text-no-wrap">
        <v-tooltip bottom
          :disabled="txtLength((title ? title : '') + ' ' + (subTitle ? ',' + subTitle : '')) < titleMaxChars">
          <template v-slot:activator="{ on, attrs }">
            <div :class="titleClass" align="left" v-bind="attrs" v-on="on">
              {{ textOverFlow((title ? title : "--") + " " + (subTitle ? "," + subTitle : "--"), titleMaxChars) }}
            </div>
          </template>
          <span>{{ title ? title : "--" }} {{ subTitle ? "," + subTitle : "--" }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="textFontDecoration mt-0">
      <v-col class="text-no-wrap flex-grow-0 pt-0" align="left">
        <span class="font14 textContent textDecorationBold primaryColor">
          {{ value ? value : "--" }}
        </span>
      </v-col>
      <v-col align="left" class="pt-0">
        <span class="font14 textContent textDecorationBold goldText">
          {{ code ? code : "--" }}
        </span>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="propertiesArray">
      <v-col v-for="item in propertiesArray" :key="item.id">
        <v-row class="flex-nowrap">
          <v-col class="align-self-center pr-0">
            <v-img :src="require('@/assets/' + item.image)"> </v-img>
          </v-col>
          <v-col class="align-self-center font14 text-no-wrap" align="left">
            <span> {{ item.value }} </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/*
 Name:CardInfo
 Description: Component to render card with image, price, code and three properties of a item

 API Property:
 width: width component
 height: height component
 image: Image path after @/assets/
 title: Title to describe the item
 titleClass: String containing classes for title
 titleMaxChars: Number containing maximum characters to be shown 
 subTitle: Subtitle to describe the item
 value: value (Number) for the item
 code: Unique code for the item

 Array with image path and value for every property
  propertiesArray: [
        {
          image: "realEstateSilder/bedroom.png",
          value: 10,
        },
        {
          image: "realEstateSilder/bathroom.png",
          value: 20,
        },
        {
          image: "realEstateSilder/sqft.png",
          value: 30,
        },
      ],

*/

import CoreHelper from "@/helpers/Core/core.js";

export default {
  name: "CardInfo",

  props: {
    width: { type: Number, default: 233 }, // width compenent
    height: { type: Number, default: 210 }, // height compenent
    image: String, // Image of card
    title: String, // title of card
    titleClass: { String, default: 'font16' },
    titleMaxChars: { Number, default: 30 },
    subTitle: String, // subtitle of card
    value: [String, Number], // value of object
    code: String, // unique code of card/object
    propertiesArray: Array, //array with image path and value for every property
  },

  computed: {
    tempImage() {
      return CoreHelper.getImagePath({ path: this.image });
    },
  },
  methods: {
    txtLength(txt) {
      try {
        return txt.length;
      } catch {
        return 0;
      }
    },
    textOverFlow(text, length) {
      try {
        if (text.length <= length) return text;
        else return text.substr(0, length) + " ..";
      } catch {
        return text;
      }
    },
  },
};
</script>

