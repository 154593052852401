<template>
  <v-card class="cardContent" @click.prevent="cardClicked()" :ripple="false">
    <v-col>
      <v-row class="topRow">
        <v-col class="flex-grow-0 pa-0 align-self-center" style="min-width: 9px !important">
          <svg v-if="unRead == true" xmlns="http://www.w3.org/2000/svg" width="9" height="47" viewBox="0 0 9 47">
            <rect id="Rectangle_3502" data-name="Rectangle 3502" width="9" height="47" :fill="unReadColor" />
          </svg>
        </v-col>
        <v-col class="flex-grow-0 pr-0 pt-0">
          <v-checkbox class="rounded-circle mt-2" @click="$event.stopPropagation()" v-model="selectedVal"
            off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-checkbox-marked-circle" />
        </v-col>
        <v-col class="pl-0">
          <v-row>
            <v-col class="pb-0 textDecorationBold font14" align="left">
              {{ timeAgo }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 font14" align="left">
              {{ createdDateString }}
            </v-col>
          </v-row>
        </v-col>
        <v-col class="flex-grow-0">
          <v-row>
            <v-col class="pb-0 textDecorationBold font14 goldText" align="right">
              {{ code ? code : "" }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 font14" align="right">
              {{ subCode ? subCode : "" }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pa-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left" class="pl-6 pt-5 pb-0">
          <v-row class="midRow"
            v-if="(fromDetails && ['Admin', 'Secretary', 'OfficeAdmin'].includes(role)) || (role != 'Admin' && fromDetails && fromDetails.userId==userId)">
            <v-col>
              <v-row>
                <v-col align="left" class="cardText">
                  <span> {{ topText ? topText : "" }} </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="align-self-center pt-1">
                  <AvatarField :data="fromDetails.img" :isImg="fromDetails.isImg" :showName="true"
                    :nameContent="fromDetails.fullName ? fromDetails.fullName + ' ' : ''" fontSizeName="14px"
                    :showSubContent="true" :subContent="fromDetails.company ? fromDetails.company : ''"
                    backgroundColor="#77BD86" :size="32" fontSizeImg="16px" />
                </v-col>
                <v-col class="flex-grow-0 pr-6 iconFlip" v-if="role != 'Admin'">
                  <Icon icon="fa6-solid:arrow-trend-down" :height="23" color="#77BD85" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="midRow"
            v-if="(toDetails && ['Admin', 'Secretary', 'OfficeAdmin'].includes(role)) || (role != 'Admin' && toDetails && toDetails.userId==userId) ">
            <v-col>
              <v-row>
                <v-col align="left" class="cardText pt-0">
                  <span>{{ botText ? botText : "" }} </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="align-self-center pt-1">
                  <AvatarField :data="toDetails.img" :isImg="toDetails.isImg" :showName="true"
                    :nameContent="toDetails.fullName ? toDetails.fullName + ' ' : ''" :showSubContent="true"
                    :subContent="toDetails.company ? toDetails.company : ''" backgroundColor="#77BD86" :size="32"
                    fontSizeName="14px" fontSizeImg="16px" />
                </v-col>
                <v-col class="flex-grow-0 pr-6" v-if="role != 'Admin'">
                  <Icon icon="fa6-solid:arrow-trend-up" :height="23" color="#666666" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="px-0 pb-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row class="botRow flex-nowrap" align="center">
        <v-col v-if="showApproveActions == true">
          <v-row class="flex-nowrap">
            <v-col align="left" class="flex-grow-0 align-self-center">
              <v-btn :width="98" :height="31" color="success" class="approveClass" @click.stop="approveAction(true)">
                <v-row align="center">
                  <v-col class="flex-grow-0 pr-0 pt-4">
                    <Icon icon="bi:check" :height="21" color="white" />
                  </v-col>
                  <v-col align="left" class="pl-0">
                    <span v-if="['Admin', 'Secretary', 'OfficeAdmin'].includes(role)">{{ $t("approve") }}</span>
                    <span v-if="role == 'Associate'">{{$t("accept")}}</span>
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
            <v-col align="left" class="flex-grow-0 align-self-center">
              <v-btn :width="108" :height="31" color="error" class="rejectClass" @click.stop="approveAction(false)">
                <v-row align="center">
                  <v-col class="flex-grow-0 pr-0 pt-4">
                    <Icon icon="teenyicons:x-small-outline" :height="21" color="white" />
                  </v-col>
                  <v-col align="left" class="pl-0"> {{ $t("reject") }} </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else-if="showCustomBtn == true && customBtnObj" align="left" class="align-self-center">
          <v-btn :width="customBtnObj.width ? customBtnObj.width : ''"
            :height="customBtnObj.height ? customBtnObj.height : 31"
            :color="customBtnObj.color ? customBtnObj.color : ''" :class="customBtnObj.class ? customBtnObj.class : ''"
            :style="customBtnObj.style ? customBtnObj.style : ''" @click.stop="customBtnClicked()">
            <v-row>
              <v-col class="flex-grow-0 align-self-center pl-2 pr-2 pt-4" v-if="
                customBtnObj.showIcon &&
                  customBtnObj.iconObj &&
                  customBtnObj.iconObj.icon
                  ? customBtnObj.showIcon
                  : false
              "
                :class="customBtnObj && customBtnObj.showIcon && customBtnObj.iconObj && customBtnObj.iconObj.class ? customBtnObj.iconObj.class : ''">
                <Icon :icon="customBtnObj.iconObj.icon"
                  :height="customBtnObj.iconObj.height ? customBtnObj.iconObj.height : '30'"
                  :color="customBtnObj.iconObj.color ? customBtnObj.iconObj.color : 'white'" />
              </v-col>
              <v-col align="left" class="pl-0 align-self-center">
                {{ customBtnObj.description ? customBtnObj.description : "" }}
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
        <v-col v-if="status && status.type && status.type == 'icon' && status.icon"
          class="flex-grow-0 text-no-wrap align-self-center" align="right">
          <Icon :icon="status.icon" :height="status.height ? status.height : '21'"
            :color="status.color ? status.color : 'white'" :class="status.class ? status.class : ''"
            :style="status.style ? status.style : ''" />
        </v-col>
        <v-col v-if="status && status.type && status.type == 'string' && status.string" class="text-no-wrap"
          :class="status.class ? status.class : ''" :style="status.style ? status.style : ''" align="right">
          {{ status.string }}
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
/* 
  Name:RequestCard
  Description: RequestCard component to generate quickly Request cards. Support approve buttons with actions, custom button with custom actions, show unread indication, selection of card, shows basic data and onClick card actions
  API Property   
  index: Number containing index of object in array
  unRead: Boolean variable to set request as read or unread
  unReadColor: String containing the color of the unread banner
  selected: Boolean variable indicating if card is selected
  selectedHandler: Handler function from parent when selected
  createdDate: String or Date containing created date information
  code: String containing code information
  subCode: String containing subCode information
  topText: String containing top text
  botText: String containing bot text
  fromDetails: Object containing fromDetails. For example:
                { fullName: "IoannisKatsarakis", company: "NewDeal", img: "/uploads/images/image1.png", isImg:true, showIcon: false, iconObj: {icon: "ant-design:up-outlined",height: 15,color: "white"} }
  toDetails: Object containing toDetails. For example:
                { fullName: "Ioannis Katsarakis", company: "NewDeal", img: "/uploads/images/image1.png",isImg:true, showIcon: false, iconObj: {icon: "ant-design:up-outlined",height: 15,color: "white"} }
  showApproveActions:Boolean variable to show/hide the Approve Action Buttons
  approveHandler: Handler function from parent when Approve action clicked
  showCustomBtn: Boolean variable to show/hide the Custom Button
  customBtnObj: Object containing custom button details. For example:
                { description: "Διαγραφή", style:"text-transform: unset !important", class:"ma-1", color:"error",showIcon:true,iconObj: {icon: "ant-design:up-outlined",height: 15,color: "white"} }
  customBtnHandler: Handler function from parent when custom button clicked
  status:  Object containing status details. For example:
                { type: "icon", icon: "md:arrow", color:"blue", height:"32", class: "", style: "" }
            OR
                { type: "string", string: "Ολοκληρωμένο", class:"textDecorationBold font14", style:"font-size: 14px;" }
    onClickHandler:  Handler function from parent when card is clicked,

    Components Used:
      AvatarField
      Icon
*/
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";
import { mapState } from "vuex";
import CoreHelper from "@/helpers/Core/core.js";
import { Icon } from "@iconify/vue2";
export default {
  name: "RequestCard",
  props: {
    index: Number,
    unRead: { type: Boolean, default: false },
    unReadColor: { type: String, default: "#eb1f28" },
    selected: { type: Boolean, default: false },
    selectedHandler: Function,
    createdDate: [String, Number],
    code: [String, Number],
    subCode: [String, Number],
    topText: String,
    botText: String,
    fromDetails: Object,
    toDetails: Object,
    showApproveActions: { type: Boolean, default: false },
    approveHandler: Function,
    showCustomBtn: { type: Boolean, default: false },
    customBtnObj: Object,
    customBtnHandler: Function,
    status: Object,
    onClickHandler: Function,
  },
  components: { AvatarField, Icon },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.userDetails.Role,
      userId: (state) => state.auth.userDetails["_id"],
    }),
    selectedVal: {
      get() {
        return this.selected;
      },
      set(value) {
        this.selectedHandler(this.index, value);
      },
    },
    createdDateString() {
      return isNaN(this.createdDate)
        ? this.createdDate
        : CoreHelper.EpochToDateTime(this.createdDate, '|');
    },
    timeAgo() {
      try {
        return CoreHelper.time_ago_card(this.createdDate);
      } catch {
        return "";
      }
    },
  },
  methods: {
    cardClicked() {
      try {
        this.onClickHandler(this.index);
      } catch (e) {
        console.error(e);
      }
    },
    approveAction(val) {
      try {
        this.approveHandler({ _id: this.index }, val);
      } catch (e) {
        console.error(e);
      }
    },
    customBtnClicked() {
      try {
        this.customBtnHandler(this.index);
      } catch (e) {
        console.error(e);
      }
    },
    getPhoto(obj) {
      if (![undefined, "", null].includes(obj.img)) {
        return obj.img;
      } else {
        if (obj.name && obj.surname) {
          return obj.name.charAt(0) + obj.surname.charAt(0);
        } else if (obj.name) {
          return obj.name.charAt(1);
        } else if (obj.surname) {
          return obj.surname.charAt(1);
        }
        return "";
      }
    },
  },
};
</script>
<style scoped>
.font14 {
  font-size: 14px;
}

.topRow {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important;
}

.midRow {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important;
}

.botRow {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
}

.cardText {
  font-size: 14px !important;
  color: #263573 !important;
  font-weight: 200 !important;
  font-family: InterBold;
}

.iconFlip {
  transform: rotateY(180deg);
}

.approveClass {
  font-size: 13px;
  background-color: #77bd85 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.rejectClass {
  font-size: 13px;
  background-color: #666666 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.cardContent {
  margin: 10px 12px 10px 0px;
}
</style>