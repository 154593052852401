import CoreHelper from "@/helpers/Core/core";
export default {
  setFilterObject(filters) {
    let object = {};
    let tagFilterArray = [];
    if (filters.fromAgent != null) {
      object.ApplicantID = filters.fromAgent._id;
      tagFilterArray.push({
        display:
          "Απο " +
          filters.fromAgent.LastName +
          " " +
          filters.fromAgent.FirstName,
        attrHead: [{ name: "fromAgent", action: "onSetFromAgent" }],
        attrValue:
          filters.fromAgent.LastName + " " + filters.fromAgent.FirstName,
        attrType: "string",
      });
    }

    if (filters.toAgent != null) {
      object.AgentID = filters.toAgent._id;
      tagFilterArray.push({
        display:
          "Προς " + filters.toAgent.LastName + " " + filters.toAgent.FirstName,
        attrHead: [{ name: "toAgent", action: "onSetToAgent" }],
        attrValue: filters.toAgent.LastName + " " + filters.toAgent.FirstName,
        attrType: "string",
      });
    }

    if (filters.estateCode != null) {
      object.EstateCode = filters.estateCode;
      tagFilterArray.push({
        display: "Κωδικός ακινήτου  " + filters.estateCode,
        attrHead: [{ name: "estateCode", action: "onSetEstateCode" }],
        attrValue: filters.estateCode,
        attrType: "string",
      });
    }
    var RegistrationDate = {};
    if (filters.createdFrom != null && filters.createdFrom != null) {
      RegistrationDate['$gt'] = CoreHelper.dateToEpoch(filters.createdFrom);
      tagFilterArray.push({
        display:
          "Καταχώρηση αιτήματος απο " +
          filters.createdFrom,
        attrHead: [
          { name: "createdFrom", action: "onSetCreatedFrom" },
        ],
        attrValue: [filters.createdFrom, filters.createdTo],
        attrType: "string",
      });
    }

    if (filters.createdTo != null && filters.createdTo != null) {
      RegistrationDate['$lt'] = CoreHelper.dateToEpoch(filters.createdTo);
      tagFilterArray.push({
        display:
          "Καταχώρηση αιτήματος έως " +
          filters.createdTo,
        attrHead: [
          { name: "createdTo", action: "onSetCreatedTo" },
        ],
        attrValue: [filters.createdFrom, filters.createdTo],
        attrType: "string",
      });
    }
    // check if date is empty
    if (Object.keys(RegistrationDate).length != 0) object.RegistrationDate = RegistrationDate;

    if (filters.approveStatus != null) {
      if (filters.approveStatus == 'ΕΓΚΕΚΡΙΜΕΝΑ') {
        object.IsApproved = true;
      } else if (filters.approveStatus == 'ΑΠΟΡΡΙΦΘΗΚΑΝ') {
        object.IsDeleted = true;
      }
      tagFilterArray.push({
        display: filters.approveStatus,
        attrHead: [{ name: "approveStatus", action: "onSetApproveStatus" }],
        attrValue: filters.approveStatus,
        attrType: "string",
      });
    }

    if (filters.requestStatus != null) {
      if (filters.requestStatus == 'Νέα') {
        object.isRead = false;
      }
      else if (filters.requestStatus == 'Διαγραμμένα') {
        object.isRemoved = true;
      } else if (filters.requestStatus == 'Έληξαν') { 
        let date = CoreHelper.dateToEpoch(new Date());
        RegistrationDate['$lt'] = CoreHelper.dateToEpoch(date-(24*3600));
        // check if date is empty
        if (Object.keys(RegistrationDate).length != 0) object.RegistrationDate = RegistrationDate;
      }
      tagFilterArray.push({
        display: filters.requestStatus,
        attrHead: [{ name: "requestStatus", action: "onSetRequestStatus" }],
        attrValue: filters.requestStatus,
        attrType: "string",
      });
    }
    return [tagFilterArray, object];
  },
};
