<template>
  <v-navigation-drawer width="1188" right v-model="show" app temporary>
    <v-col>
      <v-row>
        <v-col align="right" class="pb-0 pr-6">
          <v-icon
            @click="closeSimpleHandler"
            class="primaryColorOnHover xClass"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-0 cusPad">
        <v-col>
          <HeaderTitle
            :title="$t('requestForCooperation') + ' / #' + data._id"
            titleClass="text-no-wrap"
            subTitleClass="text-no-wrap"
            :subTitle="expireTimeLeft + data.RegistrationDateFormatted"
            :showIcon="true"
            :iconObj="
              data.IsApproved != true &&
              data.isDeleted != true &&
              data.HasAccepted != true &&
              data.isRemoved != true
                ? { icon: 'mdi:timer-sand-empty', color: '#E52628' }
                : null
            "
          />
        </v-col>
        <v-col class="align-self-center">
          <v-row
            justify="end"
            align="right"
            class="pr-3"
            v-if="getShowApproveBtn"
          >
            <v-col align="left" class="flex-grow-0">
              <v-btn
                :width="115"
                :height="40"
                color="success"
                class="approveClass"
                @click="approveAction(true)"
              >
                <v-row align="center">
                  <v-col class="flex-grow-0 pr-0 pt-4">
                    <Icon icon="bi:check" :height="21" color="white" />
                  </v-col>
                  <v-col align="left" class="pl-0">
                    <span v-if="role == 'Admin'">{{ $t("approve") }}</span>
                    <span v-if="role == 'Associate'">{{ $t("accept") }}</span>
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
            <v-col align="left" class="flex-grow-0">
              <v-btn
                :width="138"
                :height="40"
                color="error"
                class="rejectClass"
                @click="approveAction(false)"
              >
                <v-row align="center">
                  <v-col class="flex-grow-0 pt-4 pr-0">
                    <Icon
                      icon="teenyicons:x-small-outline"
                      :height="21"
                      color="white"
                    />
                  </v-col>
                  <v-col align="left" class="pl-0"> {{ $t("reject") }} </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-0 pr-0">
          <v-divider> </v-divider>
        </v-col>
      </v-row>
      <v-row class="cusPad">
        <v-col>
          <v-row>
            <v-col v-if="loadingEstateInfo == true">
              <v-skeleton-loader max-width="233" type="card" />
            </v-col>
            <v-col v-else @click="openEstateSlider(estateInfo)">
              <CardInfo
                :width="233"
                :height="210"
                :image="estateInfo.MainPhoto ? estateInfo.MainPhoto : ''"
                :title="
                  estateInfo.Type +
                  ' ' +
                  $t('propTo') +
                  ' ' +
                  estateInfo.Task +
                  ',' +
                  estateInfo.Address
                "
                titleClass="font14"
                :titleMaxChars="35"
                :subTitle="estateInfo.Floors"
                :value="estateInfo.Price"
                :code="estateInfo.EstateCode"
                :propertiesArray="[
                  {
                    image: 'realEstateSilder/bedroom.png',
                    value:
                      estateInfo.characteristics &&
                      estateInfo.characteristics.bedrooms
                        ? estateInfo.characteristics.bedrooms
                        : '-',
                  },
                  {
                    image: 'realEstateSilder/bathroom.png',
                    value:
                      estateInfo.characteristics &&
                      estateInfo.characteristics.bathrooms
                        ? estateInfo.characteristics.bathrooms
                        : '-',
                  },
                  {
                    image: 'realEstateSilder/sqft.png',
                    value: estateInfo.Unit
                      ? estateInfo.Unit + ' ' + $t('sqft4')
                      : '-',
                  },
                ]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ReadField
                :fontSize="fontSizeRF"
                :fontFamilyLabel="fontFamilyLabel"
                :fontFamilyValue="fontFamilyValue"
                :label="$t('comments')"
                :data="
                  estateInfo.comments && estateInfo.comments.length > 0
                    ? estateInfo.comments[0]
                    : '--'
                "
                :isUnderline="false"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row
            v-if="
              ['Admin', 'Secretary', 'OfficeAdmin'].includes(role) ||
              (data.HasAccepted == true && data.IsApproved == true)
            "
          >
            <v-col v-if="loadingEstateInfo == true" class="pl-0 ml-n1">
              <v-skeleton-loader max-width="233" type="list-item-avatar" />
            </v-col>
            <v-col v-else-if="data.CustomerName || data.CustomerSurName">
              <v-row>
                <v-col class="listHeader" align="left">
                  <span> {{ $t("cusInfo") }} </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pr-0" align="left">
                  <AvatarField
                    :size="41"
                    :data="
                      estateInfo.ClientPhoto
                        ? estateInfo.ClientPhoto
                        : estateInfo.ClientName && estateInfo.ClientSurName
                        ? estateInfo.ClientName.charAt(0) +
                          estateInfo.ClientSurName.charAt(0)
                        : estateInfo.ClientName
                        ? estateInfo.ClientName.charAt(1)
                        : estateInfo.ClientSurName
                        ? estateInfo.ClientSurName.charAt(1)
                        : ''
                    "
                    :isImg="estateInfo.ClientPhoto ? true : false"
                    backgroundColor="#77BD86"
                    :showName="true"
                    :nameContent="
                      estateInfo.ClientName + ' ' + estateInfo.ClientSurName
                    "
                    :showSubContent="true"
                    :subContent="estateInfo.ClientPhones"
                    subContentClass="textInterMedium"
                    fontSizeImg="18px"
                    rowClass=" flex-nowrap "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ReadField
                :fontSize="fontSizeRF"
                :fontFamilyLabel="fontFamilyLabel"
                :fontFamilyValue="fontFamilyValue"
                :label="$t('dateTimeNoteShort')"
                :data="showcase"
                :isUnderline="false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ReadField
                :fontSize="fontSizeRF"
                :fontFamilyLabel="fontFamilyLabel"
                :fontFamilyValue="fontFamilyValue"
                :label="$t('coopStatusAppr')"
                :data="getStatusTextAdmin"
                :showIcon="true"
                :iconObj="getStatusIconAdmin"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ReadField
                :fontSize="fontSizeRF"
                :fontFamilyLabel="fontFamilyLabel"
                :fontFamilyValue="fontFamilyValue"
                :label="$t('coopStatusRec')"
                :data="getStatusTextAssociate"
                :showIcon="true"
                :iconObj="getStatusIconAssociate"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row class="cusPad">
        <v-col>
          <BigAvatar
            :headerTitle="$t('coopFrom')"
            :image="data.ApplicantPhoto"
            :fullName="data.ApplicantName"
            :company="data.ApplicantCompany"
            :role="'Agent Pro'"
            :label="$t('noRatings')"
            labelFont="14"
            labelColor="#A6B1C2"
            :val="null"
            colorRating="#243774"
            :size="5"
            :showResult="false"
            :isImg="data.isImg"
          />
        </v-col>
        <v-col>
          <BigAvatar
            :headerTitle="$t('coopTo')"
            :image="data.AgentPhoto"
            :fullName="data.AgentName"
            :company="data.AgentCompany"
            :role="'Agent Pro'"
            :label="$t('noRatings')"
            labelFont="14"
            labelColor="#A6B1C2"
            :val="null"
            colorRating="#243774"
            :size="5"
            :showResult="false"
            :isImg="data.isImgAgent"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-0 pr-0">
          <v-divider> </v-divider>
        </v-col>
      </v-row>
      <v-row v-if="data.IsDeleted && data.IsDeleted == true" class="cusPad">
        <v-col>
          <ReadField
            :fontSize="fontSizeRF"
            :fontFamilyLabel="fontFamilyLabel"
            :fontFamilyValue="fontFamilyValue"
            :label="
              $t('comment') + '(' + data.DeniedBy == data.AgentID
                ? data.AgentName
                : data.DeniedBy == data.ApplicantID
                ? data.ApplicantName
                : '' + ')'
            "
            :data="data.reasonOfDenial"
            :isUnderline="false"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
/* 
  Name:CooperationSlider
  Description: Slider component to generate quickly request slider. Support close and approve action buttons function  
  API Property 
  data: Object containing the data to be displayed on slider
  onCloseHandler: Function tha calls parent when on close
  approveHandler: Function that calls parent when approve

  Components Used:
    HeaderTitle
    Icon
    CardInfo
    ReadField
    AvatarField
    BigAvatar

  Endpoints Functions Called:
    -RealEstateRepo
      get

    -ReqCooperationRepo
      update

  */
import { mapState } from "vuex";
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import CardInfo from "@/components/Fields/CardInfo/CardInfo.vue";
import ReadField from "@/components/Fields/ReadField/ReadField";
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";
import BigAvatar from "@/components/Fields/BigAvatar/BigAvatar.vue";
import Repository from "@/services/Repository";
import CoreHelper from "@/helpers/Core/core.js";

const RealEstateRepo = Repository.get("realEstate");
const ReqCooperationRepo = Repository.get("reqCooperation");

import { Icon } from "@iconify/vue2";
export default {
  name: "CooperationSlider",
  props: {
    data: Object,
    onCloseHandler: Function,
    openEstateDetailsSlider: Function,
    approveHandler: Function,
  },
  components: {
    HeaderTitle,
    Icon,
    CardInfo,
    ReadField,
    AvatarField,
    BigAvatar,
  },
  data() {
    return {
      show: true,
      loadingEstateInfo: false,
      fontSizeRF: "14px", //font-size for ReadingField component
      fontFamilyLabel: "InterBold", //font-family for ReadingField component label
      fontFamilyValue: "InterMedium", //font-family for ReadingField component value
      estateInfo: [],
    };
  },
  created() {
    if (this.data.EstateCode != undefined) {
      this.fetchEstateInfo();
    }
    if (this.data.isRead != undefined && !this.data.isRead) {
      this.setIsRead();
    }
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeSimpleHandler();
      }
    },
  },
  computed: {
    ...mapState({
      role: (state) => state.auth.userDetails.Role,
    }),
    expireTimeLeft() {
      if (
        this.data.IsApproved != true &&
        this.data.isDeleted != true &&
        this.data.HasAccepted != true &&
        this.data.isRemoved != true
      ) {
        return CoreHelper.ExpireAt(this.data.RegistrationDate, 1440) + " | ";
      }
      return "";
    },
    showcase() {
      if (this.data.showcaseDateTime != undefined) {
        return this.data.showcaseDateTime;
      }
      return "";
    },
    getShowApproveBtn() {
      if (this.role == "Admin") {
        if (!this.data.IsApproved && !this.data.IsDeleted) {
          return true;
        }
        return false;
      } else if (this.role == "Associate") {
        if (
          !this.data.IsDeleted &&
          (this.data.HasAccepted == undefined || !this.data.HasAccepted)
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    getStatusTextAdmin() {
      if (this.data.IsApproved && !this.data.isRemoved) {
        return `Έγινε έγκριση | Εστάλη ${this.data.RegistrationDateFormatted}`;
      } else if (
        !this.data.IsApproved &&
        !this.data.IsDeleted &&
        !this.data.isRemoved
      ) {
        return `Αναμονή Έγκρισης | Εστάλη ${this.data.RegistrationDateFormatted}`;
      } else if (
        this.data.IsDeleted == true &&
        this.data.DeniedBy &&
        this.data.DeniedBy != this.data.AgentID
      ) {
        return `Έγινε απόρριψη | Εστάλη ${this.data.RegistrationDateFormatted}`;
      }
      return "";
    },
    getStatusIconAdmin() {
      if (this.data.IsApproved && !this.data.isRemoved) {
        return { icon: "ant-design:check", color: "#77BD86", height: 25 };
      } else if (
        !this.data.IsApproved &&
        !this.data.IsDeleted &&
        !this.data.isRemoved
      ) {
        return { icon: "mdi:timer-sand-empty", color: "#E52628", height: 25 };
      } else if (
        this.data.IsDeleted == true &&
        this.data.DeniedBy &&
        this.data.DeniedBy != this.data.AgentID
      ) {
        return { icon: "ant-design:close", color: "#77BD86", height: 25 };
      }
      return {};
    },
    getStatusTextAssociate() {
      if (this.data.HasAccepted && !this.data.isRemoved) {
        return `Έγινε αποδοχή | Εστάλη ${this.data.RegistrationDateFormatted}`;
      } else if (
        (!this.data.HasAccepted || this.data.HasAccepted == undefined) &&
        !this.data.IsDeleted &&
        !this.data.isRemoved
      ) {
        return `Αναμονή Αποδοχής | Εστάλη ${this.data.RegistrationDateFormatted}`;
      } else if (
        this.data.IsDeleted &&
        this.data.IsDeleted == true &&
        this.data.DeniedBy &&
        this.data.DeniedBy == this.data.AgentID
      ) {
        return `Έγινε απόρριψη | Εστάλη ${this.data.RegistrationDateFormatted}`;
      }
      return "";
    },
    getStatusIconAssociate() {
      if (this.data.HasAccepted && !this.data.isRemoved) {
        return { icon: "ant-design:check", color: "#77BD86", height: 25 };
      } else if (
        (!this.data.HasAccepted || this.data.HasAccepted == undefined) &&
        !this.data.IsDeleted &&
        !this.data.isRemoved
      ) {
        return { icon: "mdi:timer-sand-empty", color: "#E52628", height: 25 };
      } else if (
        this.data.IsDeleted &&
        this.data.IsDeleted == true &&
        this.data.DeniedBy &&
        this.data.DeniedBy == this.data.AgentID
      ) {
        return { icon: "ant-design:close", color: "#77BD86", height: 25 };
      }
      return {};
    },
  },
  methods: {
    closeSimpleHandler() {
      this.onCloseHandler();
    },
    openEstateSlider(estate) {
      this.openEstateDetailsSlider(estate._id);
    },
    async fetchEstateInfo() {
      this.loadingEstateInfo = true;
      var resp = await RealEstateRepo.get(
        { $and: { EstateCode: this.data.EstateCode }, $or: {} },
        { page: 0, items: 1 }
      );
      if (resp.data.estates.length > 0) {
        this.estateInfo = resp.data.estates[0];
        this.loadingEstateInfo = false;
      }
    },
    async approveAction(action) {
      this.approveHandler(this.data, action);
    },
    async setIsRead() {
      await ReqCooperationRepo.update(this.data._id, { isRead: true });
    },
  },
};
</script>
<style scoped>
.approveClass {
  font-size: 16px;
  background-color: #77bd85 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.rejectClass {
  font-size: 16px;
  background-color: #666666 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.cusPad {
  padding-left: 90px;
  padding-right: 90px;
}

@media only screen and (max-width: 940px) {
  .cusPad {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 810px) {
  .cusPad {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .cusPad {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>
