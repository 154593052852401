var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"align":"center","justify":"center","elevation":0}},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.actionClose}},[_c('v-icon',{staticClass:"primaryColorOnHover"},[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',[_c('v-img',{staticClass:"ma-0 pa-0",attrs:{"max-height":"189","max-width":"65","height":"189","width":"65","src":require('@/assets/' + this.image),"lazy-src":require('@/assets/' + this.image)}})],1)],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('div',{staticClass:"font-weight-bold",style:({
          color: _vm.titleColor,
          fontSize: _vm.titleFont + 'px',
          fontFamily: 'Century Gothic',
        })},[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('span',{staticClass:"font-weight-bold",style:({
          color: _vm.subTitleColor,
          fontSize: _vm.subTitleFont + 'px',
          fontFamily: 'Century Gothic',
        })},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")])])],1),_c('v-row',{staticClass:"ma-0 pa-0 mt-4 mb-4",staticStyle:{"height":"180px","width":"476px"}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-textarea',{staticClass:"textAreaContent",staticStyle:{"height":"180px","width":"476px"},attrs:{"label":_vm.textAreaTitle,"hide-details":true,"outlined":"","rows":"6","row-height":"60","no-resize":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-btn',{staticClass:"textDecorationBold white--text btnTextArea2",attrs:{"elevation":"10","color":_vm.buttonColor},on:{"click":_vm.actionMessage}},[_vm._v(" "+_vm._s(_vm.buttonTitle)+" "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }