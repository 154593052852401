<template>
  <v-navigation-drawer width="360px" style="height: 100%; position: fixed" right v-model="show" app temporary>
    <v-col>
      <v-row class="justify-between">
        <v-col class="pb-0" align="right">
          <v-icon @click="closeHandler" class="topIcons primaryColorOnHover xClass"> mdi-close </v-icon>
        </v-col>
      </v-row>
      <v-row class="middleRow">
        <v-col class="pl-0 pr-0">
          <v-list-item class="pl-0 pr-0">
            <v-expansion-panels v-model="openPanel" accordion>
              <v-expansion-panel>
                <v-expansion-panel-header style="background-color: #EBEBEB ">
                  <v-row>
                    <v-col>
                      <h5 class="textDecorationBold primaryColor" style="font-size: 16px !important">
                        {{ $t("moreFilters") }}
                      </h5>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content align="left" class="panelOverflow">
                  <!-- <v-row>
                    <v-col class="pb-0 mt-1">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("from2") }}
                      </h6>
                    </v-col>
                  </v-row> -->
                  <v-row class="pt-6 pb-3">
                    <v-col>
                      <v-autocomplete v-model="agentFrom" :label="$t('from2')" :items="agentListFrom" item-value="_id"
                        item-text="fullname" return-object :search-input.sync="searchAgentFrom" hide-no-data flat
                        clearable hide-selected 
                        v-on:keyup.enter="onSetFilter" attach/>

                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("to2") }}
                      </h6>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col>
                      <v-autocomplete v-model="agentTo" :items="agentListTo" :label="$t('to2')" height="30" dense
                        :loading="agentAutocompleteLoaderTo" :search-input.sync="searchAgentTo" clearable hide-no-data
                        return-object flat hide-selected item-text="fullname" item-value="_id" 
                        v-on:keyup.enter="onSetFilter" attach/>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6
                        style="font-size: 14px !important"
                        class="text-uppercase textDecorationBold"
                      >
                        {{ $t("propertyCode") }}
                      </h6>
                    </v-col>
                  </v-row> -->
                  <v-row class="pb-3">
                    <v-col>
                      <v-text-field v-model="estateCode" :label="$t('propertyCode')" clearable color="primary"
                        hide-details="auto" 
                        v-on:keyup.enter="onSetFilter"/>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0">
                      <h6 style="font-size: 14px !important" class="text-uppercase textDecorationBold">
                        {{ $t("requestPost") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-0 pr-0 pl-1 dateClearable">
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense outlined :placeholder="$t('from')" clearable
                            v-model="createdDateFromFormat" prepend-inner-icon="mdi-calendar" @blur="
                              createdDateFrom = parseDate(
                                createdDateFromFormat,
                                'createdDateFrom'
                              )
                            " 
                        v-on:keyup.enter="onSetFilter">
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker locale="el" v-model="createdDateFrom" scrollable @input="menu = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="flex-grow-0 pl-0 pr-0 pb-0">
                      <v-icon>mdi-minus</v-icon>
                    </v-col>
                    <v-col class="pt-1 pb-0 pl-0 pr-1 dateClearable">
                      <v-menu ref="menu1" v-model="menu1" left :close-on-content-click="false"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field dense outlined clearable :placeholder="$t('toFilter')"
                            v-model="createdDateToFormat" prepend-inner-icon="mdi-calendar" @blur="
                              createdDateTo = parseDate(
                                createdDateToFormat,
                                'createdDateTo'
                              )
                            " 
                        v-on:keyup.enter="onSetFilter">
                            <template v-slot:prepend-inner>
                              <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker locale="el" v-model="createdDateTo" scrollable @input="menu1 = false">
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6 mt-0">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 style="font-size: 14px !important" class="text-uppercase textDecorationBold">
                        {{ $t("approvalStatus") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="approveStatus" flex>
                        <v-btn value="ΕΓΚΕΚΡΙΜΕΝΑ" class="ma-1 btn" color="primary" outlined>
                          {{ $t("approved") }}
                        </v-btn>
                        <v-btn value="ΑΠΟΡΡΙΦΘΗΚΑΝ" class="ma-1 btn" color="primary" outlined>
                          {{ $t("rejected") }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="ml-n6 mr-n6">
                    <v-col class="pl-0 pr-0">
                      <v-divider />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0 pb-0">
                      <h6 style="font-size: 14px !important" class="text-uppercase textDecorationBold">
                        {{ $t("requestStatus") }}
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <v-btn-toggle v-model="requestStatus" flex>
                        <v-btn value="Νέα" class="ma-1 btn" color="primary" outlined>
                          {{ $t("new") }}
                        </v-btn>
                        <v-btn value="Έληξαν" class="ma-1 btn" color="primary" outlined>
                          {{ $t("expired") }}
                        </v-btn>
                        <v-btn value="Διαγραμμένα" class="ma-1 btn" color="primary" outlined>{{ $t("deleted") }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-footer padless absolute style="background-color: #ebebeb">
            <v-row class="pt-5 pb-5">
              <v-col>
                <v-btn elevation="5" align="center" class="ma-1 btn" color="error" @click="onSetFilter">
                  {{ $t("search") }}
                </v-btn>
              </v-col>
              <v-col class="align-self-center" aling="center">
                <a class="text-decoration-underline errorColor" @click="clearFiltersHandler">
                  {{ $t("clear") }}</a>
              </v-col>
            </v-row>
          </v-footer>
        </v-col>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>

/*
 Name:Filters
 Description: Component for the requests filters

 API Property:
    onSearch: Function on seatvh
    onCloseHandler: Function on close handler

    Endpoints Functions Called:
      -ContactRepo
        getAgents
*/
import { mapState, mapActions } from "vuex";
import CoreHelper from "@/helpers/Core/core";
import Repository from "@/services/Repository";
const ContactRepo = Repository.get("contacts");

const isFormattedDate = (dateString) => {
  if (dateString == null) return false;
  let regEx = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
  return dateString.match(regEx) != null;
};

export default {
  name: "Filters",
  props: {
    onSearch: Function,
    onCloseHandler: Function,
  },
  created() {
    this.init();
    this.createdDateFrom = this.filters.createdFrom;
    this.createdDateTo = this.filters.createdTo;
    this.agentFrom ? this.fetchAgentsListFrom(this.agentFrom, "id") : true;
    this.agentTo ? this.fetchAgentsListTo(this.agentTo, "id") : true;
  },
  data() {
    return {
      show: true,
      openPanel: 0,
      menuCreatedFrom: false,
      menuCreatedTo: false,
      agentAutocompleteLoaderFrom: false,
      agentAutocompleteLoaderTo: false,
      agentListFrom: [],
      agentListTo: [],
      searchAgentTo: null,
      searchAgentFrom: null,
      createdDateFromFormat: null,
      createdDateFrom: null,
      createdDateToFormat: null,
      createdDateTo: null,
      menu: false,
      menu1: false,
    };
  },
  watch: {
    show() {
      if (!this.show) {
        this.onCloseHandler();
      }
    },
    searchAgentTo(val) {
      val && val != this.agentTo && this.fetchAgentsListTo(val,'splitName');
    },
    searchAgentFrom(val) {
      val && val != this.agentFrom && this.fetchAgentsListFrom(val,'splitName');
    },
    createdDateFrom() {
      this.createdDateFromFormat = this.formatDate(this.createdDateFrom);
      this.onSetCreatedFrom(
        this.createdDateFrom == null || this.createdDateFrom == undefined
          ? null
          : this.createdDateFrom
      );
    },
    createdDateTo() {
      this.createdDateToFormat = this.formatDate(this.createdDateTo);
      this.onSetCreatedTo(
        this.createdDateTo == null || this.createdDateTo == undefined
          ? null
          : this.createdDateTo
      );
    },
  },
  computed: {
    ...mapState({
      filters: (state) => state.request,
      userDetails: (state) => state.auth.userDetails,
    }),
    agentFrom: {
      get() {
        return this.filters.fromAgent;
      },
      set(value) {
        this.onSetFromAgent(value);
      },
    },
    agentTo: {
      get() {
        return this.filters.toAgent;
      },
      set(value) {
        this.onSetToAgent(value);
      },
    },
    approveStatus: {
      get() {
        return this.filters.approveStatus;
      },
      set(value) {
        this.onSetApproveStatus(value);
      },
    },
    requestStatus: {
      get() {
        return this.filters.requestStatus;
      },
      set(value) {
        this.onSetRequestStatus(value);
      },
    },
    estateCode: {
      get() {
        return this.filters.estateCode;
      },
      set(value) {
        this.onSetEstateCode(value);
      },
    },
  },
  methods: {
    ...mapActions([
      "onSetRequestStatus",
      "onSetApproveStatus",
      "onSetCreatedTo",
      "onSetCreatedFrom",
      "onSetToAgent",
      "onSetFromAgent",
      "onClearFilters",
      "onSetEstateCode",
    ]),
    async init() {
      await this.fetchAgentsListFrom();
      await this.fetchAgentsListTo();
    },
    async fetchAgentsListFrom(val, type) {
      try {
        this.agentAutocompleteLoaderFrom = true;

        let resp = await this.fetchAgentsList(val, type);
        this.agentListFrom = resp.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        this.agentAutocompleteLoaderFrom = false;
      } catch (e) {
        this.agentAutocompleteLoaderFrom = false;
        throw e;
      }
    },
    async fetchAgentsListTo(val, type) {
      try {
        this.agentAutocompleteLoaderTo = true;

        let resp = await this.fetchAgentsList(val, type);
        this.agentListTo = resp.map((a) => {
          return { ...a, fullname: a.LastName + " " + a.FirstName };
        });
        this.agentAutocompleteLoaderTo = false;
      } catch (e) {
        this.agentAutocompleteLoaderTo = false;
        throw e;
      }
    },
    async fetchAgentsList(val, type) {
      type
      try {
        var filters;
        filters = [
          { $or: [{ IsDeleted: false }, { IsDeleted: { $exists: false } }] },
        ];
        if (val != null) {
          if (filters.length == 1) {
            filters.push([]);
          }
          filters[1].push({
            ["LastName"]: {
              $regex: CoreHelper.toRegex(val.split(" ")[0]),
              $options: "i",
            },
          });
        }
        let resp = await ContactRepo.getAgents(filters, { page: 0, items: 60 });
        
        return resp.data.agents;
      } catch (e) {
        return e;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date, dateName) {
      if (!date || !isFormattedDate(date)) {
        if (dateName == "createdFrom") {
          this.createdDateFrom = null;
          this.createdDateFromFormat = null;
        } else if (dateName == "createdTo") {
          this.createdDateTo = null;
          this.createdDateToFormat = null;
        }
        return null;
      }

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    onSetFilter() {
      this.onSearch("genericSearch");
    },
    closeHandler() {
      this.onCloseHandler();
    },
    clearFiltersHandler() {
      this.searchAgentTo = null;
      this.searchAgentFrom = null;
      this.createdDateFromFormat = null;
      this.createdDateFrom = null;
      this.createdDateToFormat = null;
      this.createdDateTo = null;
      this.onClearFilters();
    },
  },
};
</script>

<style scoped>
.panelOverflow {
  height: calc(100vh - 278px);
  overflow: auto;
}

.v-expansion-panel--active+.v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: 0px;
}

.primaryColor {
  color: #263573 !important;
}

.middleRow {
  max-height: calc(100vh - 120px);
  overflow: auto;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.v-btn-toggle {
  border-radius: 4px;
  display: block;
  max-width: 100%;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 48px;
  min-height: 0;
}

.v-btn-toggle--group>.v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle>.v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
  height: 35px;
}
</style>
