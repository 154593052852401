<template>
  <v-card
    align="center"
    justify="center"
    :elevation="0"
    style="overflow: hidden"
  >
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="actionClose">
        <v-icon class="primaryColorOnHover">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-row class="ma-0 pa-0">
      <v-col>
        <v-img
          class="ma-0 pa-0"
          max-height="189"
          max-width="65"
          height="189"
          width="65"
          :src="require('@/assets/' + this.image)"
          :lazy-src="require('@/assets/' + this.image)"
        >
        </v-img>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <div
          v-bind:style="{
            color: titleColor,
            fontSize: titleFont + 'px',
            fontFamily: 'Century Gothic',
          }"
          class="font-weight-bold"
        >
          {{ title }}
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <span
          :style="{
            color: subTitleColor,
            fontSize: subTitleFont + 'px',
            fontFamily: 'Century Gothic',
          }"
          class="font-weight-bold"
        >
          {{ subTitle }}
        </span>
      </v-col>
    </v-row>

    <v-row class="ma-0 pa-0 mt-4 mb-4" style="height: 180px; width: 476px">
      <v-col class="ma-0 pa-0">
        <v-textarea
          :label="textAreaTitle"
          :hide-details="true"
          class="textAreaContent"
          outlined
          v-model="message"
          rows="6"
          row-height="60"
          no-resize
          style="height: 180px; width: 476px"
        >
        </v-textarea>
        <v-btn
          elevation="10"
          class="textDecorationBold white--text btnTextArea2"
          :color="buttonColor"
          @click="actionMessage"
        >
          {{ buttonTitle }} <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/*
 Name:PopWithAction
 Description: Reject pop up component with the ability to send message to all collaborators
 API Property:

 image: image path after @/assets
 height: height of popup
 width: width of popup
 title: title of popup
 titleFont: title font
 titleColor: title color
 subTitle: sub title of popup
 subTitleFont: sub title font
 subTitleColor: sub title color
 textAreaTitle: text area title
 buttonTitle: button title of popup
 buttonColor: button color of popup
 actionHandler: parent function when button clicked
 actionClose: handler function to update parent state when close button pressed
*/

export default {
  name: "PopWithAction",

  props: {
    image: String, // image path after @/assets
    height: Number, // height of popup
    width: Number, // width of popup
    title: String, // title of popup
    subTitle: String, // sub title of popup
    titleFont: Number, // title font
    titleColor: String, // title color
    subTitleFont: Number, // sub title font
    subTitleColor: String, // subTitle color of popup
    textAreaTitle: String, // text area title
    buttonTitle: String, // button title of popup
    buttonColor: String, // button color of popup
    actionHandler: Function, // handler function when button clicked
    closeHandler: Function, //handler function to update parent state when close button pressed
  },
  data() {
    return {
      message: null,
    };
  },
  methods: {
    actionMessage() {
      this.actionHandler(this.message);
    },

    actionClose() {
      this.closeHandler();
    },
  },
};
</script>
<style scoped>
.textAreaContent {
  color: #263573 !important;
  border: #263573 !important;
  border-color: #263573 !important;
}

.btnTextArea2 {
  position: relative;
  bottom: 22%;
  text-transform: none;
  left: 35%;
}

@media screen and (max-width: 1500px) and (min-width: 500px) {
  .btnTextArea2 {
    left: 35%;
    bottom: 22%;
  }
}

@media screen and (max-width: 220px) {
  .btnTextArea2 {
    left: 25%;
    bottom: 32%;
  }
}
</style>
