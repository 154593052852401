
export default {
  headers: [
    {
      text: "from2",
      align: "start",
      sortable: false,
      customSortable: false,
      sort: "",
      value: "ApplicantName",
    },
    {
      text: "realEstate2",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "EstateCode",
    },
    {
      text: "coopTo",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "AgentName",
    },
    {
      text: "date",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "RegistrationDateFormatted",
    },
    {
      text: "condition",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "IsApproved",
    },
    {
      text: "choices",
      align: "start",
      sortable: false,
      customSortable: true,
      sort: "",
      value: "actions",
    },
  ],
  templates: [
    {
      elementType: "customSpan",
      column: "EstateCode",
      rowClass: "orangeTextInput textDecorationBold",
      title: "EstateCode"
    },
    {
      column: "ApplicantName",
      rowClass: " flex-nowrap ",
      dataObject: "ApplicantPhoto",
      content: "ApplicantName",
      nameContentClass: "primaryColor",
      elementType: "customAvatar",
      subContent: "ApplicantCompany",
      subContentClass: "primaryColor",
      isImg: "isImg",
      size: 30,
      fontSizeName: '16px',
      fontSizeSubContent: '16px',
      fontSizeImg: '16px'
    },
    {
      column: "AgentName",
      rowClass: " flex-nowrap ",
      dataObject: "AgentPhoto",
      content: "AgentName",
      nameContentClass: "primaryColor",
      elementType: "customAvatar",
      subContent: "AgentCompany",
      subContentClass: "primaryColor",
      isImg: "isImgAgent",
      size: 30,
      fontSizeName: '16px',
      fontSizeSubContent: '16px',
      fontSizeImg: '16px'
    },
    {
      column: "RegistrationDateFormatted",
      rowClass: " flex-nowrap ",
      dataObject: "",
      content: "expireTimeLeft",
      nameContentClass: "cusTab textDecorationBold font14 text-no-wrap pb-0 pl-0",
      elementType: "plainField",
      subContent: "RegistrationDateFormatted",
      subContentClass: "cusTab font14 text-no-wrap pt-0 pl-0",
      isImg: "",
      size: 30,
      fontSizeName: '16px',
      fontSizeSubContent: '16px',
      fontSizeImg: '16px'
    },
    {
      elementType: "spanIcon",
      column: "IsApproved",
      isIcon: "isIconStatus",
      iconObj: "iconObj",
      textData: "iconText",
      textClass: "iconTextClass"
    }
  ],
  cooperationFilters: [
    {
      id: 1,
      description: "Όλα τα αιτήματα"
    },
    {
      id: 2,
      description: "Εισερχόμενα"
    },
    {
      id: 3,
      description: "Εξερχόμενα"
    }
  ]
};
