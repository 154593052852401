<template>
  <v-card :max-width="width" :max-height="height" :width="width" :height="height" :elevation="0" align="center">
    <v-row>
      <v-col class="pb-0 font16 textInterBold primaryColor">
        <span> {{ headerTitle }} </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center" class="pt-0 pb-0 font16 textContent textDecorationBold text-no-wrap">
        <span>
          {{ fullName }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <v-avatar color="#E8E8EA" size="160">
          <v-avatar size="119" color="rgb(119,189,134)">
            <span class="white--text textDecorationBold font29" v-if="!isImg">{{ image }}</span>
            <v-img v-else :src="tempImage" :lazy-src="tempImage" alt="John" />
          </v-avatar>
        </v-avatar>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <v-col class="pt-0 font14 text-no-wrap errorColor">
        <span>
          {{ company }}
        </span>
      </v-col>
    </v-row>
    <v-row class="ml-0 mt-0 pa-0">
      <v-row v-if="val <= 0">
        <v-col>
          <span :style="{
            color: labelColor,
            fontSize: labelFont + 'px',
          }" class="textDecorationBold">
            <i> {{ label }} </i>
          </span>
        </v-col>
      </v-row>
      <v-row class="justify-center" v-else>
        <v-col class="flex-grow-0">
          <RatingField colorRating="#E89008" :val="val" :showResult="showResult" :size="size" />
        </v-col>
        <v-col class="flex-grow-0 pl-0">
          <span class="font14 orangeText">
            {{ val }}
          </span>
        </v-col>
      </v-row>
    </v-row>

    <v-row>
      <v-col class="text-no-wrap font14 pt-0">
        <span> {{ role }} </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/*
 Name:BigAvatar
 Description: Component to render card with name, sector, image and ratings of an avatar
 API Property:
  height: height of card
  width: width of card
  headerTitle: Header title
  isImg: If component render img or name
  image: Image path
  fullName:Avatar name
  company: Avatar company
  role: Avatar role
  label: Label that appears if the stars (val rating) is zero
  labelFont: Label font if the stars is zero
  labelColor: Label color if the stars is zero
  val: Number of rating 
  colorRating: Color of stars and border
  size:number of stars
  showResult: if component show result of stars
  changeHandler: handler function to update parent state

  Components Used:
    RatingField
*/
import CoreHelper from "@/helpers/Core/core.js";
import RatingField from "@/components/Fields/RatingField/RatingField.vue";
export default {
  name: "BigAvatar",
  components: {
    RatingField,
  },

  props: {
    height: String, //height of card
    width: String, //width of card
    headerTitle: String, //Header title
    isImg: { type: Boolean, default: true }, //If component render img or name
    image: String, //Image path
    fullName: String,
    company: String, //Avatar company
    role: String, //Avatar role
    label: String, //Label that appears if the stars (val rating) is zero
    labelFont: String, //Label font if the stars is zero
    labelColor: String, //Label color if the stars is zero
    val: [String, Number], //Number of rating
    colorRating: String, //Color of stars and border
    size: { type: Number, default: 5 }, //number of stars
    showResult: { type: Boolean, default: false }, //if component show result of stars
  },

  computed: {
    tempImage() {
      return CoreHelper.getImagePath({ path: this.image });
    },
  },
  methods: {
    txtLength(txt) {
      try {
        return txt.length;
      } catch {
        return 0;
      }
    },
    textOverFlow(text, length) {
      try {
        if (text.length <= length) return text;
        else return text.substr(0, length) + " ..";
      } catch {
        return text;
      }
    },
  },
};
</script>
