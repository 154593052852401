<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col align="center" cols="12" class="pb-9">
          <v-img
            v-if="type == 'success'"
            :src="require('@/assets/general/approveCoop.png')"
            width="315"
            height="351"
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12">
          <span
            class="textDecorationBold"
            v-html="content"
            style="font-size: 20px; color: var(--v-primary-base)"
          >
            <!-- {{ content }} -->
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
/*
 Name:Success
 Description:Popup modal for the approves cooperation

 API Property
  content: Message to show to user
  iconVar: Icon name to show
  iconColor: Color of icon
  type: Message type ("error" or "success")

*/
// import { Icon } from "@iconify/vue2";
export default {
  name: "ApCooperationPopup",
  components: {
    // Icon,
  },
  props: {
    content: String, // Text Content
    type: String,
    // iconVar: String, // Icon name
    // iconColor: String, // Icon color
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 0px 16px 0px 16px;
}

.v-card {
  padding: 13px 0px 13px 0px;
}
.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px 42px 8px 1px;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}
</style>
